import React from "react";
import Animate from "react-smooth";
import photo1 from "../assets/emily/1.jpg";
import photo2 from "../assets/emily/2.jpg";
import photo3 from "../assets/emily/3.jpg";
import photo4 from "../assets/emily/4.jpg";
import photo5 from "../assets/emily/5.jpg";
import photo6 from "../assets/emily/6.jpg";
import photo7 from "../assets/emily/7.jpg";
import photo8 from "../assets/emily/8.jpg";
import photo9 from "../assets/emily/9.jpg";
import photo10 from "../assets/emily/10.jpg";
import "../assets/css/pictures.css";

const Pictures = () => {
  return (
    <div className="pictures">
      <ul>
        <li>
          <img src={photo1} />
        </li>
        <li>
          <img src={photo2} />
        </li>
        <li>
          <img src={photo3} />
        </li>
        <li>
          <img src={photo4} />
        </li>
        <li>
          <img src={photo5} />
        </li>
        <li>
          <img src={photo6} />
        </li>
        <li>
          <img src={photo7} />
        </li>
        <li>
          <img src={photo8} />
        </li>
        <li>
          <img src={photo9} />
        </li>
        <li>
          <img src={photo10} />
        </li>
        <li>
          Gemaakt met <span>&hearts;</span> in De Goorn
        </li>
      </ul>
    </div>
  );
};

export default Pictures;
