import React, { Component } from "react";
import Animate from "react-smooth";
import TimeAgo from "./components/timeAgo";
import Belly from "./components/belly";
import Pictures from "./components/pictures";
import hearth from "./assets/heart.svg";
import "./App.css";
import emily1 from "./assets/emily/1.jpg";
import emily2 from "./assets/emily/2.jpg";
import emily3 from "./assets/emily/3.jpg";
import emily4 from "./assets/emily/4.jpg";
import emily5 from "./assets/emily/5.jpg";
import emily6 from "./assets/emily/6.jpg";
import emily7 from "./assets/emily/7.jpg";
import emily8 from "./assets/emily/8.jpg";
import emily9 from "./assets/emily/9.jpg";
import emily10 from "./assets/emily/10.jpg";
import belly1 from "./assets/buik/1.jpg";
import belly2 from "./assets/buik/2.jpg";
import belly3 from "./assets/buik/3.jpg";
import belly4 from "./assets/buik/4.jpg";
import belly5 from "./assets/buik/5.jpg";
import belly6 from "./assets/buik/6.jpg";
import belly7 from "./assets/buik/7.jpg";
import belly8 from "./assets/buik/8.jpg";
import belly9 from "./assets/buik/9.jpg";
import belly10 from "./assets/buik/10.jpg";

class App extends Component {
  state = {
    time: "00:00",
    bellyTime: false,
    authenticated: false
  };

  name = "Emily";
  nameOther = "Marianne José Roet";
  time = "11:33";
  birthDate = new Date(2019, 2, 1, 11, 33, 0);

  timeInput = React.createRef();

  delay = ms => {
    return {
      style: {},
      duration: ms
    };
  };

  steps = {
    privacy: [
      { style: { opacity: 0 } },
      {
        style: { opacity: 1, transform: "translate3d(0, -20px, 0)" },
        duration: 1000
      }
    ],

    form: [
      { style: { opacity: 1 } },
      {
        style: { opacity: 0, transform: "translate3d(0, -100px, 0)" },
        duration: 1000
      }
    ],

    question: [
      { style: { opacity: 0 } },
      {
        style: { opacity: 1, transform: "translate3d(0, -20px, 0)" },
        duration: 2000
      }
    ],

    time: [
      { style: { opacity: 0, transform: "translate3d(0, 20px, 0)" } },
      {
        style: { opacity: 1 },
        duration: 2000
      }
    ],
    pictures: [
      { style: { opacity: 0, transform: "translate3d(0, 40px, 0)" } },
      {
        style: { opacity: 1, transform: "translate3d(0, 0, 0)" },
        duration: 2000
      }
    ],
    name: [
      {
        style: {
          opacity: 0
        }
      },
      {
        style: {
          opacity: 1
        },
        duration: 2000
      },
      this.delay(500),
      {
        style: {
          transform: "translate3d(0, -100px, 0)"
        },
        duration: 1000
      }
    ],
    opacity: ms => {
      return [
        {
          style: {
            opacity: 0
          }
        },
        {
          style: {
            opacity: 1
          },
          duration: ms
        }
      ];
    }
  };

  handleBellyAnimated = () => {
    this.setState({
      authenticated: true
    });
  };

  handleChangeTime = event => {
    this.setState({
      time: event.target.value
    });

    setTimeout(() => {
      if (this.state.time === this.time) {
        this.setState({
          bellyTime: true
        });

        this.timeInput.current.blur();
      }
    }, 100);
  };

  render() {
    const { time, bellyTime } = this.state;

    return (
      <div className="App">
        <link rel="preload" href={emily1} as="image" />
        <link rel="preload" href={emily2} as="image" />
        <link rel="preload" href={emily3} as="image" />
        <link rel="preload" href={emily4} as="image" />
        <link rel="preload" href={emily5} as="image" />
        <link rel="preload" href={emily6} as="image" />
        <link rel="preload" href={emily7} as="image" />
        <link rel="preload" href={emily8} as="image" />
        <link rel="preload" href={emily9} as="image" />
        <link rel="preload" href={emily10} as="image" />
        <link rel="preload" href={belly1} as="image" />
        <link rel="preload" href={belly2} as="image" />
        <link rel="preload" href={belly3} as="image" />
        <link rel="preload" href={belly4} as="image" />
        <link rel="preload" href={belly5} as="image" />
        <link rel="preload" href={belly6} as="image" />
        <link rel="preload" href={belly7} as="image" />
        <link rel="preload" href={belly8} as="image" />
        <link rel="preload" href={belly9} as="image" />
        <link rel="preload" href={belly10} as="image" />
        {this.state.authenticated && (
          <div class="back">
            <div className="name">{this.name}</div>
            <Animate begin="1000" steps={this.steps.opacity(1000)}>
              <div className="nameBirth">{this.nameOther}</div>
            </Animate>
            <Animate begin="2000" steps={this.steps.opacity(3000)}>
              <div class="all">
                <span>
                  al ons later
                  <br />
                  is met jou
                </span>
                <img src={hearth} />
              </div>
            </Animate>
            <Animate begin="3000" steps={this.steps.opacity(2000)}>
              <div class="ago">
                <TimeAgo name={this.name} date={this.birthDate} />
              </div>
            </Animate>
            <Animate begin="4000" steps={this.steps.pictures}>
              <div>
                <Pictures />
              </div>
            </Animate>
          </div>
        )}

        <Animate
          canBegin={this.state.authenticated}
          attributeName="opacity"
          from="1"
          to="0"
        >
          <div class="front">
            <div className="center">
              {bellyTime && <Belly onAnimated={this.handleBellyAnimated} />}
              <Animate canBegin={bellyTime} steps={this.steps.form}>
                <div>
                  <Animate steps={this.steps.name}>
                    <div className="name">{this.name}</div>
                  </Animate>
                  <Animate begin="3000" steps={this.steps.privacy}>
                    <div className="privacy">Voor wat privacy...</div>
                  </Animate>
                  <Animate begin="3500" steps={this.steps.question}>
                    <div className="question">Hoe laat is ze geboren?</div>
                  </Animate>
                  <Animate begin="4500" steps={this.steps.time}>
                    <div>
                      <input
                        ref={this.timeInput}
                        type="time"
                        className="time"
                        value={time}
                        onInput={this.handleChangeTime}
                        placeholder="00:00"
                      />
                    </div>
                  </Animate>
                </div>
              </Animate>
            </div>
          </div>
        </Animate>
      </div>
    );
  }
}

export default App;
