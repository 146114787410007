import React, { Component } from "react";

class TimeAgo extends Component {
  state = {
    currentDate: new Date()
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({
        currentDate: new Date()
      });
    }, 1000);
  }

  sentence() {
    let n =
      (this.state.currentDate.getTime() - this.props.date.getTime()) / 1000;

    const day = Math.floor(n / (24 * 3600));

    n = n % (24 * 3600);
    const hour = Math.floor(n / 3600);

    n %= 3600;
    const minutes = Math.floor(n / 60);

    n %= 60;
    const seconds = Math.floor(n);

    const bla =
      day +
      " dag" +
      (day === 1 ? "" : "en") +
      ", " +
      hour +
      " " +
      (hour === 1 ? "uur" : "uren") +
      ", " +
      minutes +
      " " +
      (minutes === 1 ? "minuut" : "minuten") +
      ", " +
      seconds +
      " seconden";

    return bla;
  }

  render() {
    return (
      <div>
        {this.props.name} is nu {this.sentence()} oud.
      </div>
    );
  }
}

export default TimeAgo;
