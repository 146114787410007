import React, { Component } from "react";
import "../assets/css/belly.css";
import Animate from "react-smooth";
import photo1 from "../assets/buik/1.jpg";
import photo2 from "../assets/buik/2.jpg";
import photo3 from "../assets/buik/3.jpg";
import photo4 from "../assets/buik/4.jpg";
import photo5 from "../assets/buik/5.jpg";
import photo6 from "../assets/buik/6.jpg";
import photo7 from "../assets/buik/7.jpg";
import photo8 from "../assets/buik/8.jpg";
import photo9 from "../assets/buik/9.jpg";
import photo10 from "../assets/buik/10.jpg";

class Belly extends Component {
  state = {};

  photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10
  ];

  done = index => {
    if (index === this.photos.length - 1) {
      this.props.onAnimated();
    }
  };

  render() {
    return (
      <div class="bellies">
        {this.photos.map((photo, index) => (
          <Animate
            begin={index * 500}
            from="0"
            to="1"
            duration="1500"
            attributeName="opacity"
            onAnimationEnd={() => this.done(index)}
          >
            <div>
              <img src={photo} />
            </div>
          </Animate>
        ))}
      </div>
    );
  }
}

export default Belly;
